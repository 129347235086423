import Backend from "./Backend";
import { getUserData } from "./UserManager";
import analyticsActions from "../helper/analytics_actions";
import { deleteSession, isSession } from "./SessionManager";

export function startSessionEvent(county: string, country: string, campaign_id?: string) {
  if (window.QAMode) return;

  if (!isSession()) { // if session hasn't started already
    const { uuid, session_id } = getUserData(true);
    
    if (session_id && uuid) {
      Backend.analyticsEvent({
        uuid, session_id,
        action: analyticsActions.session_started,
        data: JSON.stringify({ county, country, campaign_id })
      })
      ?.catch(() => {
        console.log("Error while initializing session")
        deleteSession()
      })
    }
  }
}
