import axios from 'axios';
import nevo from '../api/nevo';
import endpoints from '../helper/endpoints';

import getSession from './SessionManager';
import IAnalyticsUser from '../interfaces/IAnalyticsUser';

export default class Backend {
  static cancellableRequest(url: string, method: string, body?: any) {
    const token = axios.CancelToken.source();
    
    if (method === 'GET') {
      return {
        promise: nevo.get(url, { cancelToken: token.token }),
        cancel: token.cancel
      }
    }
    // DEFINITELY POST
    else {
      return {
        promise: nevo.post(url, body, { cancelToken: token.token }),
        cancel: token.cancel
      }
    }
  }
  
  static formRequest(
    name: string, email: string, message: string, form_type: string, 
    phone: string, company?: string,
  ) {
    const data: any = { 
      email,
      form_type,
      session_id: getSession(),
      form_data: JSON.stringify({name, message, phone, company})
    }

    if (window.QAMode) data.qa = 'true';

    const requestURL = `${endpoints.form}/`

    return this.cancellableRequest(requestURL, 'POST', data);
  }

  static registerUser(user_data: IAnalyticsUser) {
    if (window.QAMode) return;
    
    const url = `${endpoints.analyticsUsers}/`

    return nevo.post(url, user_data);
  }

  static analyticsEvent(event_data: any) {
    if (window.QAMode) return;

    const url = `${endpoints.analytics}/`

    return nevo.post(url, event_data); 
  }

  // For Posts (blog)
  static fetchLearnContent(contentType = 'insights', start_at: number, page_size: number) {
    let base_url: string;

    base_url = endpoints.blog + `/${contentType}?order_by=updated_at&order=desc&active=1`;
    
    const end_et = start_at + page_size;
    
    const requestURL = `${base_url}&start_at=${start_at}&end_at=${end_et}`

    return this.cancellableRequest(requestURL, 'GET');
  }
  static getPostBySlug(slug: string) {
    return nevo.get(`${endpoints.post}/url/${slug}`);
  }
  // End of Posts (blog)
}
