
export default function startQA() {
  sessionStorage.setItem('QA', 'true')
  window.QAMode = true
}
export function exitQA() {
  sessionStorage.removeItem('QA')
  window.QAMode = false
}
export function isQAStorage() {
  return sessionStorage.getItem('QA') === 'true'
}