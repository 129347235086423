import TagManager from 'react-gtm-module';
import isQAorDev from '../utils/DevelopmentMode';

export const GA_TRACKING_ID = 'GTM-TM6WF6X'

export const tagManagerArgs = {
  gtmId: GA_TRACKING_ID,
  events: {
    nevoLogoHeader: 'Nevo Logo Header',
    nevoLogoSideMenu: 'Nevo Logo Side Menu',

    whatWeDoHeader: 'What We Do Header',
    whatWeDoFooter: 'What We Do Footer',
    whatWeDoSideMenu: 'What We Do Side Menu',

    telematicsHeader: 'Telematics Header',
    telematicsFooter: 'Telematics Footer',
    telematicsSideMenu: 'Telematics Side Menu',

    nevoieHeader: 'Nevo ie Header',
    nevoieFooter: 'Nevo ie Footer',
    nevoieSideMenu: 'Nevo ie Side Menu',

    termsClick: 'Terms Click',
    privacyClick: 'Privacy Click',
    cookiesClick: 'Cookies Click',

    fleetTransitionHeader: 'Fleet Transition Header',
    fleetTransitionFooter: 'Fleet Transition Footer',
    fleetTransitionSideMenu: 'Fleet Transition Side Menu',

    chargingSolutionsHeader: 'Charging Solutions Header',
    chargingSolutionsFooter: 'Charging Solutions Footer',
    chargingSolutionsSideMenu: 'Charging Solutions Side Menu',

    energyManagementHeader: 'Energy Management Header',
    energyManagementFooter: 'Energy Management Footer',
    energyManagementSideMenu: 'Energy Management Side Menu',

    emobilityEducationHeader: 'Emobility Education Header',
    emobilityEducationFooter: 'Emobility Education Footer',
    emobilityEducationSideMenu: 'Emobility Education Side Menu',

    insightsHeader: 'Insights Header',
    insightsFooter: 'Insights Footer',
    insightsSideMenu: 'Insights Side Menu',

    caseStudiesHeader: 'Case Studies Header',
    caseStudiesFooter: 'Case Studies Footer',
    caseStudiesSideMenu: 'Case Studies Side Menu',

    energyHeader: 'Energy Header',
    energyFooter: 'Energy Footer',
    energySideMenu: 'Energy Side Menu',
    esbRequestACallbackClick: 'ESB Request A Callback Click',

    contactUsSideMenu: 'Contact Us Side Menu',
    contactUsFooter: 'Contact Us Footer',

    aboutUsSideMenu: 'About Us Side Menu',
    aboutUsFooter: 'About Us Footer',

    clickedOnHamburgerMenu: 'Clicked on hamburger menu',
    closeSideMenu: 'Close Side Menu',
    goBackSideMenu: 'Go Back Side Menu',

    geotabScheduleDemoClick: 'Geotab Schedule Demo Click',
    clickedOnElectricVehicleChargingLinkOnEnergyPage: 'Clicked on Electric Vehicle Charging link on Energy page',
    clickedOnEnergyManagementOnFleetPage: 'Clicked on Energy Management on Fleet page',
    clickedOnLightCommercialVehiclesOnFleetPage: 'Clicked on Light Commercial Vehicles on Fleet page',
    clickedOnReduceYourFleets: 'Clicked on Reduce Your Fleets',
    clickedOnManageCosts: 'Clicked on Manage Costs',

    clickedOnExtendBio: 'Clicked on Extend Bio',
    clickedOnDriveIncOnAboutUsPage: 'Clicked on Drive Inc on About Us page',
    clickedOnTradeBidOnAboutUsPage: 'Clicked on Trade Bid on About Us page',
    clickedOnSweepOnAboutUsPage: 'Clicked on Sweep on About Us page',
    clickedOnInstaBidOnAboutUsPage: 'Clicked on InstaBid on About Us page',

    // contact us page
    clickedOnSubmitForm: 'Clicked on Submit Form',
    clickedOnTypeOfEnquiry: 'Clicked on Type of Enquiry',
    clickedOnYourName: 'Clicked on Your Name',
    clickedOnCompany: 'Clicked on Company',
    clickedOnEmail: 'Clicked on Email',
    clickedOnPhoneNumber: 'Clicked on Phone Number',
    clickedOnQuestionInput: 'Clicked on Question',
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (window.gtag && !isQAorDev()) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

const eventGTM = (event: string) => {
  if (isQAorDev()) {
    console.log(event)
    return;
  }

  try {
    TagManager.dataLayer({
      dataLayer: {
        event: event, 
      }
    });
  }
  catch (e) {
    console.error(e, "Google Tag Manager Event. Cookie doesn't work | incognito mode on")
  }
}

export default eventGTM