import { v4 } from "uuid";

const NO_TRACKING = "NO_TRACKING";

// If user allows to track statistics in CookieBot,
// retrieve session_id.
export default function getSession() {
  if (!Cookiebot?.consent?.statistics) return NO_TRACKING

  return sessionStorage.getItem("session_id") || NO_TRACKING;
}

// If user allows to track statistics in CookieBot,
// generate session_id, store it in sessionStorage.
export function startSession() {
  if (!Cookiebot?.consent?.statistics) return null

  const session_id = v4();
  sessionStorage.setItem("session_id", session_id);
  return session_id;
}

export function deleteSession() {
  sessionStorage.removeItem("session_id");
}

export function isSession() {
  return !!sessionStorage.getItem("session_id")
}
