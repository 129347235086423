
import axios from "axios";

export default axios.create({
  baseURL: process.env.NEVO_BASE_API,
  headers: {
    'Content-Type': 'application/json',
  }
});

