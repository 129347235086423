/* eslint-disable @next/next/no-img-element */
import React from 'react'

import { exitQA } from '../../utils/QAManager'
import { toast } from 'react-toastify'

import styles from './styles.module.scss'
import CloseIcon from '../../public/assets/icons/close-white.svg'

export default function QABanner() {
  const onExitQAMode = () => {
    exitQA();
    
    toast.warning('You quit QA Mode.')

    setTimeout(() => {
      // reloading website to initialise session, Google Analytics etc.
      window.location.reload();
    }, 1500)
  }
  
  return (
    <div className={styles.qaBanner}>
      <h1 className={styles.qaHeader}>QA</h1>
      <img onClick={onExitQAMode} className={styles.close} src={CloseIcon.src} alt="Disable QA Mode" />
    </div>
  )
}